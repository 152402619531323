import { Dialog } from '@/components/ui';
import { DialogContent, DialogHeader, DialogTitle } from '@/components/ui/dialog';
import { DialogDescription } from '@radix-ui/react-dialog';

import { useTranslations } from 'next-intl';
import { forwardRef, useImperativeHandle, useState } from 'react';
import { TripEvent } from '@/views/my-schedule/my-schedule.interface';
import { ManageTripForm } from './manage-trip-form';

interface Props {
  onUpdated?: () => void;
}

export type ManageTripDialogRef = {
  open: (trip?: TripEvent) => void;
};

const ManageTripDialog = forwardRef<ManageTripDialogRef, Props>(({ onUpdated }, ref) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [trip, setTrip] = useState<TripEvent>();
  const t = useTranslations();

  useImperativeHandle(ref, () => ({
    open: (trip?: TripEvent) => {
      setIsOpen(true);
      if (trip) {
        setTrip(trip);
      }
    },
  }));

  const handleAction = () => {
    setIsOpen(false);
    onUpdated?.();
  };

  return (
    <>
      <Dialog open={isOpen} onOpenChange={setIsOpen}>
        <DialogContent onOpenAutoFocus={(e) => e.preventDefault()} className="w-full max-w-screen-xl">
          <DialogHeader>
            <DialogTitle>{trip ? t('Pages.Schedule.editTrip') : t('Pages.Schedule.createTrip')} </DialogTitle>
            <DialogDescription></DialogDescription>
          </DialogHeader>
          <div className="pt-4">
            {/* {trip ? (
              EDIT TRIP
            ) : ( */}
              <ManageTripForm onCancel={() => setIsOpen(false)} onRefresh={handleAction} />
            {/* )} */}
          </div>
        </DialogContent>
      </Dialog>
    </>
  );
});

ManageTripDialog.displayName = 'ManageTripDialog';

export { ManageTripDialog };
