'use client';

import { Button, Popover, PopoverContent, PopoverTrigger } from '@/components/ui';
import { ChevronLeft, ChevronRight, Plus } from 'lucide-react';
import { useTranslations } from 'next-intl';
import dayjs from '@/lib/dayjsConfig';
import { useEffect, useState } from 'react';
import { PERMISSIONS_TYPES } from '@/lib/RBAC/enums/permissions-types';
import { useIsAllowed } from '@/lib/RBAC';

interface Props {
  onChange: (value: { start: dayjs.Dayjs; end: dayjs.Dayjs }) => void;
  onAddShift: () => void;
  onAddTrip: () => void;
}

export function ScheduleHeader({ onChange, onAddShift, onAddTrip }: Props) {
  const t = useTranslations();
  const { checkPermissions } = useIsAllowed();

  const [currentWeek, setCurrentWeek] = useState<{ start: dayjs.Dayjs; end: dayjs.Dayjs }>({
    start: dayjs().startOf('week'),
    end: dayjs().endOf('week'),
  });

  useEffect(() => {
    onChange(currentWeek);
  }, [JSON.stringify(currentWeek)]);

  function reset() {
    setCurrentWeek({
      start: dayjs().startOf('week'),
      end: dayjs().endOf('week'),
    });
  }

  function nextWeeks() {
    setCurrentWeek((prev) => {
      const nextStartWeek = prev.start.add(1, 'week');
      return {
        start: nextStartWeek,
        end: nextStartWeek.endOf('week'),
      };
    });
  }

  function prevWeeks() {
    setCurrentWeek((prev) => {
      const prevStartWeek = prev.start.add(-1, 'week');
      return {
        start: prevStartWeek,
        end: prevStartWeek.endOf('week'),
      };
    });
  }

  function isWeekToday(): boolean {
    const today = dayjs();
    return today.isBetween(currentWeek.start, currentWeek.end, 'day', '[]');
  }

  return (
    <>
      <nav className="flex w-full flex-row items-center justify-between pl-4 pr-6">
        <h1 className="mt-2 py-4 text-2xl font-bold">{t('Pages.Schedule.title')}</h1>
        <span className="text-[28px] font-normal text-gray-800">{dayjs(currentWeek.start).format('MMMM YYYY')}</span>
        <div className="flex flex-row items-center space-x-4">
          <Button variant="ghost" size="icon" onClick={prevWeeks}>
            <ChevronLeft />
          </Button>
          <Button variant="ghost" size="icon" onClick={nextWeeks}>
            <ChevronRight />
          </Button>

          <Button variant="outline" size="lg" disabled={isWeekToday()} onClick={reset}>
            {t('Buttons.today')}
          </Button>

          {checkPermissions([PERMISSIONS_TYPES.ADD_SHIFTS, PERMISSIONS_TYPES.ADD_TRIPS], 'some') && (
            <>
              <Popover>
                <PopoverTrigger asChild>
                  <Button size="sm">
                    <Plus className="size-4" />
                  </Button>
                </PopoverTrigger>
                <PopoverContent withoutPortal align="start" className="mr-5 w-40">
                  <div className="flex flex-col space-y-4">
                    {checkPermissions([PERMISSIONS_TYPES.ADD_SHIFTS]) && (
                      <Button size="lg" variant="outline" className="w-full" onClick={onAddShift}>
                        {t('Buttons.addShift')}
                      </Button>
                    )}
                    {checkPermissions([PERMISSIONS_TYPES.ADD_TRIPS]) && (
                      <Button size="lg" variant="outline" className="w-full" onClick={onAddTrip}>
                        {t('Buttons.addTrip')}
                      </Button>
                    )}
                  </div>
                </PopoverContent>
              </Popover>
            </>
          )}
        </div>
      </nav>
    </>
  );
}
