import axiosInterceptorInstance from '@/axiosInterceptorInstance';
import { DefaultError, UseMutationOptions, useMutation, useQueryClient } from '@tanstack/react-query';
import dayjs from '@/lib/dayjsConfig';
import * as _ from 'lodash';
import { ManageTripValidationSchema } from '@/views/schedule/manage-trip/manage-trip-validation-schema';

export interface Request extends ManageTripValidationSchema {}

export type Options = Omit<UseMutationOptions<string, DefaultError, Request>, 'mutationKey' | 'mutationFn'>;

export const useCreateTripMutation = (options?: Options) => {
  const queryClient = useQueryClient();
  const _mutation = async (value: Request): Promise<string> => {
    const { date, endTime, startTime, miles } = value;
    const startTime24h = dayjs(startTime, 'hh:mm A');
    const endTime24h = dayjs(endTime, 'hh:mm A');

    const parsedValue = {
      startAt: dayjs(date).set('hour', startTime24h.hour()).set('minute', startTime24h.minute()).toISOString(),
      endAt: dayjs(date).set('hour', endTime24h.hour()).set('minute', endTime24h.minute()).toISOString(),
      miles,
      therapistId: value.therapist ? JSON.parse(value.therapist).id : null,
    };
    const body = _.omitBy(parsedValue, (v) => _.isNil(v) || v === '');
    const { data } = await axiosInterceptorInstance.post(`trips`, body);
    return data;
  };

  return useMutation({
    mutationKey: ['trips'],
    mutationFn: _mutation,
    onSettled: (data, error) => {
      if (!error) {
        queryClient.resetQueries({ queryKey: ['dashboard'] });
        queryClient.invalidateQueries({ queryKey: ['shifts'] });
        queryClient.resetQueries({
          queryKey: ['shifts', 'my-schedule', 'therapist', `${dayjs().startOf('week').add(1, 'day').toISOString()}`],
        });
        queryClient.invalidateQueries({
          queryKey: ['shifts', 'my-schedule', 'therapist', `${dayjs().startOf('week').add(1, 'day').toISOString()}`],
        });
      }
    },
    ...options,
  });
};
