'use client';

import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
  Select,
  SelectTrigger,
  SelectValue,
  SelectContent,
  SelectItem,
  TagItem,
  DatePicker,
  Input,
} from '@/components/form';
import { useTranslations } from 'next-intl';
import { cn, generateTimeSlots } from '@/lib/utils';
import { Button } from '@/components/ui';
import { ScrollArea, toast, ToastTypeEnums } from '@/components/common';
import { DialogFooter } from '@/components/ui/dialog';
import { useEffect, useState } from 'react';
import { useTherapistsQuery } from '@/hooks/fetchers/queries/therapists/useTherapistsQuery';
import { Therapist } from '@/views/all-therapists/interfaces/therapists.interface';
import { manageTripValidationSchema, ManageTripValidationSchema } from './manage-trip-validation-schema';
import dayjs from '@/lib/dayjsConfig';
import { useCreateTripMutation } from '@/hooks/fetchers/mutations/trips/useCreateTripMutation';

const TIME_SLOTS = generateTimeSlots(6);

interface Props {
  onCancel?: () => void;
  onRefresh: () => void;
}

export function ManageTripForm({ onCancel, onRefresh }: Props) {
  const t = useTranslations();
  const [keywordTherapist, setKeywordTherapist] = useState('');

  const { data: therapists, isLoading: isLoadingTherapists } = useTherapistsQuery<TagItem[]>(
    {
      search: keywordTherapist,
      skip: 0,
      take: 200,
    },
    {
      enabled: true,
      select: (value: any): TagItem[] =>
        value.data.map((therapist: Therapist) => ({
          label: `${therapist.firstName} ${therapist.lastName}`,
          value: {
            deactivatedAt: therapist.deactivatedAt,
            firstName: therapist.firstName,
            id: therapist.id,
            lastName: therapist.lastName,
          },
        })),
    }
  );

  const onSearchByTherapists = (value: string) => {
    setKeywordTherapist(value);
  };

  const { mutate: create, isPending: isCreating } = useCreateTripMutation({
    onSuccess: () => {
      toast({
        title: t('Toasts.success'),
        typeIcon: ToastTypeEnums.SUCCESS,
        description: t('Toasts.shiftCreated'),
      });
      form.reset();
      onRefresh();
    },
  });

  const form = useForm<ManageTripValidationSchema>({
    mode: 'onChange',
    resolver: zodResolver(manageTripValidationSchema),
    defaultValues: {
      therapist: '',
      date: dayjs(),
      startTime: '',
      endTime: '',
      miles: 0,
    },
  });

  const endTimeSelector = form.watch('endTime');
  const startTimeSelector = form.watch('startTime');

  useEffect(() => {
    if (endTimeSelector && form.getValues('endTime')) {
      form.trigger('endTime');
    }
    if (startTimeSelector && form.getValues('startTime')) {
      form.trigger('startTime');
    }
  }, [endTimeSelector, startTimeSelector]);

  const onSubmit = () => {
    if (form.formState.isValid) {
      const value = form.getValues();
      create(value);
    } else {
      form.trigger();
    }
  };

  return (
    <>
      <Form {...form}>
        <ScrollArea className="h-[calc(100vh-300px)] overflow-auto">
          <div className="grid grid-cols-form-cols-2 gap-8 pl-1">
            <div className="relative">
              <FormField
                control={form.control}
                name="therapist"
                render={({ field, fieldState }) => (
                  <FormItem>
                    <FormLabel>{t('Forms.therapist.label')}</FormLabel>
                    <Select
                      onOpenChange={() => setKeywordTherapist('')}
                      value={field.value}
                      onValueChange={field.onChange}
                    >
                      <FormControl>
                        <SelectTrigger className={cn('', !!fieldState.error && 'border-destructive')}>
                          <SelectValue
                            placeholder={
                              isLoadingTherapists ? t('Common.loadingWait') : t('Forms.therapist.placeholder')
                            }
                          />
                        </SelectTrigger>
                      </FormControl>
                      <SelectContent search onChangeSearch={onSearchByTherapists}>
                        <SelectItem value={null as any}>None</SelectItem>
                        {field.value && field.value !== '' ? (
                          <SelectItem
                            value={field.value}
                          >{`${JSON.parse(field.value).firstName} ${JSON.parse(field.value).lastName}`}</SelectItem>
                        ) : null}

                        {therapists
                          ?.filter((item) => (field.value ? item.value.id !== JSON.parse(field.value).id : true))
                          .map((item) => (
                            <SelectItem key={item.value.id} value={JSON.stringify(item.value)}>
                              {item.label}
                            </SelectItem>
                          ))}
                      </SelectContent>
                    </Select>
                    <FormMessage className="absolute" />
                  </FormItem>
                )}
              />
            </div>

            <div className="relative">
              <FormField
                control={form.control}
                name="miles"
                render={({ field, fieldState }) => (
                  <FormItem>
                    <FormLabel isRequired>{t('Forms.miles.label')}</FormLabel>
                    <FormControl>
                      <Input
                        hasError={!!fieldState.error}
                        className="w-full"
                        {...field}
                        type="number"
                        placeholder={t('Forms.miles.placeholder')}
                      />
                    </FormControl>
                    <FormMessage className="absolute" />
                  </FormItem>
                )}
              />
            </div>

            <div className="relative">
              <FormField
                control={form.control}
                name="startTime"
                render={({ field, fieldState }) => (
                  <FormItem>
                    <FormLabel isRequired>{t('Forms.startTime.label')}</FormLabel>
                    <Select value={field.value} onValueChange={field.onChange}>
                      <FormControl>
                        <SelectTrigger className={cn('', !!fieldState.error && 'border-destructive')}>
                          <SelectValue placeholder={t('Forms.startTime.placeholder')} />
                        </SelectTrigger>
                      </FormControl>
                      <SelectContent>
                        {TIME_SLOTS.map((item) => (
                          <SelectItem key={item} value={item}>
                            {item}
                          </SelectItem>
                        ))}
                      </SelectContent>
                    </Select>
                    <FormMessage className="absolute" />
                  </FormItem>
                )}
              />
            </div>

            <div className="relative">
              <FormField
                control={form.control}
                name="endTime"
                render={({ field, fieldState }) => (
                  <FormItem>
                    <FormLabel isRequired>{t('Forms.endTime.label')}</FormLabel>
                    <Select value={field.value} onValueChange={field.onChange}>
                      <FormControl>
                        <SelectTrigger className={cn('', !!fieldState.error && 'border-destructive')}>
                          <SelectValue placeholder={t('Forms.endTime.placeholder')} />
                        </SelectTrigger>
                      </FormControl>
                      <SelectContent>
                        {TIME_SLOTS.map((item) => (
                          <SelectItem key={item} value={item}>
                            {item}
                          </SelectItem>
                        ))}
                      </SelectContent>
                    </Select>
                    <FormMessage className="absolute" />
                  </FormItem>
                )}
              />
            </div>

            <div className="relative">
              <FormField
                control={form.control}
                name="date"
                render={({ field, fieldState }) => (
                  <FormItem>
                    <FormLabel isRequired>{t('Forms.date.label')}</FormLabel>
                    <DatePicker disableDates="none" field={field} isError={fieldState.error} />
                    <FormMessage className="absolute" />
                  </FormItem>
                )}
              />
            </div>
          </div>
        </ScrollArea>
      </Form>

      <DialogFooter className="mt-10 flex h-20 w-full flex-row items-center justify-between border-t border-t-gray-300 pt-4 sm:justify-between">
        <Button onClick={onCancel} variant="ghost" type="button" size="lg">
          {t('Buttons.cancel')}
        </Button>

        <Button type="button" onClick={onSubmit} size="lg" disabled={isCreating}>
          {t('Buttons.save')}
        </Button>
      </DialogFooter>
    </>
  );
}
