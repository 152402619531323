import { cn, transformDateToTime } from '@/lib/utils';
import { Bus, CarFront, Clock, Route, Timer } from 'lucide-react';
import { TripEvent } from '../my-schedule/my-schedule.interface';

interface Props {
  value: TripEvent;
  onPreview: (value: TripEvent) => void;
}

export function TravelCardItem({ value, onPreview }: Props) {
  return (
    <div
      onClick={() => onPreview(value)}
      className={cn(
        'flex h-28 w-full cursor-pointer flex-col justify-between rounded-sm border-2 border-blue-200 bg-white'
      )}
    >
      <div className="p-2">
        <div className="mb-2 block text-sm font-medium text-blue-950">
          <div className="grid min-w-0">
            <span className="truncate text-gray-800">Travel Card</span>
            {/* <span className="mt-2 truncate font-bold uppercase text-gray-400">Travel Card</span> */}
          </div>
          <div className="mt-2 grid min-w-0 space-y-1">
            <span className="flex flex-row items-center truncate text-xs text-gray-800">
              <Route className="mr-1 size-4 text-gray-600" />
              {value.estimatedMileage.toFixed(2)} Miles
            </span>
            <span className="flex flex-row items-center truncate text-xs text-gray-800">
              <Clock className="mr-1 size-4 text-gray-600" />
              {(value.estimatedTime * 60).toFixed(2)} Min
            </span>
          </div>
        </div>
      </div>
      <p
        className={cn(
          'flex flex-row items-center justify-between whitespace-nowrap rounded-b bg-gray-400 px-2 text-xs font-light text-white',
          value.payrollEntry && 'bg-gray-700'
        )}
      >
        {`${transformDateToTime(value.startAt)} - ${transformDateToTime(value.endAt)}`}{' '}
        <span>
          {/* <Bus className="size-5 ml-1" /> */}
          <CarFront className="ml-1 size-5" />
        </span>
      </p>
    </div>
  );
}
