import { z } from 'zod';
import dayjs, { Dayjs } from 'dayjs';

export const manageTripValidationSchema = z.object({
  therapist: z.string().min(1, { message: 'Forms.therapist.required' }),
  date: z.custom<Dayjs>(
    (value) => {
      return dayjs.isDayjs(value);
    },
    { message: 'Forms.endsOn.required' }
  ),
  startTime: z.string().min(1, { message: 'Forms.startTime.required' }),
  endTime: z.string().min(1, { message: 'Forms.endTime.required' }),
  miles: z
    .string()
    .transform((value) => (value === '' ? undefined : Number(value)))
    .refine((value) => value === undefined || !isNaN(value), { message: 'Forms.invalid' })
    .refine((value) => value === undefined || value >= 0, { message: 'Forms.miles.min' })
    .refine((value) => value === undefined || value <= 1e9, { message: 'Forms.miles.max' }),
});

export type ManageTripValidationSchema = z.infer<typeof manageTripValidationSchema>;
